
import { defineComponent, reactive, ref, watch, getCurrentInstance, computed } from 'vue';
import { useResetOptions, ResetMethods } from './ResetOptions';
import { generatePasswd, copyFun } from './utils';
import { resetPassWd, sendMailForResetPassword, MailType } from '@/api/company/users';
import { PasswordRules, HELP_MSG } from '@/utils/validate';

// 状态码
enum ResCode {
  Success,
}
// 密码重置
interface ResetFormState {
  newPassword: any[];
}
export default defineComponent({
  name: 'ResetPasswordDialog',
  setup() {
    const visible = ref(false);
    // ----------------------------
    const userId = ref(0);
    const newPassword = ref('');
    const formData = reactive({
      newPassword,
    });
    const rules: ResetFormState = {
      newPassword: PasswordRules,
    };
    const { configuraion } = useResetOptions();
    const resetForm: any = ref(null);
    // 获取组件实例
    const instance = getCurrentInstance();

    const msgTips = (type: string, content: string) => {
      (instance as any).proxy.$message({
        type,
        message: content,
      });
    };
    // 复制密码
    const handleCopy = () => {
      copyFun(newPassword.value);
    };
    // 保存密码
    const savePassword = () =>
      // 校验密码-》保存密码-》copy 密码
      new Promise<string>((resolve, reject) => {
        resetForm.value.validate(async (valid: boolean) => {
          if (valid) {
            const { code } = await resetPassWd({
              userId: userId.value,
              newPassword: newPassword.value,
            });
            if (code === ResCode.Success) {
              // 复制到剪切板上
              handleCopy();
              resolve('密码重置成功');
            } else {
              reject(new Error('密码重置失败'));
            }
          } else {
            reject(new Error('校验未通过'));
          }
        });
      });

    // 发送邮件
    const sendMail = async () => {
      const RESET_PASSWORD_PATH = '/reset-password';
      const { code } = await sendMailForResetPassword({
        type: MailType.resetPassword,
        userId: userId.value,
        url: new URL(RESET_PASSWORD_PATH, window.location.origin),
      });
      if (code === ResCode.Success) {
        return Promise.resolve('重置邮件已发送到邮箱，请及时更新');
      }
      throw new Error('重置邮件发送失败');
    };
    watch(
      () => configuraion.isRandom,
      (v) => {
        if (v) {
          // 如果当前选择的是随机密码
          newPassword.value = generatePasswd(12);
        } else {
          newPassword.value = '';
        }
      },
    );
    const resetData = () => {
      configuraion.current = '';
    };
    // 每次打开新的对话框重置
    watch(visible, (v) => {
      if (v) {
        resetData();
      }
    });
    //
    const open = (id: number) => {
      userId.value = id;
      visible.value = true;
    };
    const close = () => {
      visible.value = false;
    };
    const submit = async () => {
      visible.value = false;
      try {
        let msg = '';
        if (configuraion.current === ResetMethods.RandomPassword) {
          // 提交随机密码
          msg = await savePassword();
        } else if (configuraion.current === ResetMethods.SendMail) {
          // 发送重置邮件
          msg = await sendMail();
        }
        msgTips('success', msg);
      } catch (error) {
        msgTips('error', (error as Error).message);
      } finally {
        visible.value = false;
      }
    };
    const disableSubmit = computed(() => configuraion.current === '');
    const cancel = () => {
      close();
    };
    return {
      visible,
      open,
      close,
      submit,
      cancel,
      userId,
      formData,
      rules,
      configuraion,
      handleCopy,
      helper: HELP_MSG,
      resetForm,
      disableSubmit,
    };
  },
});
