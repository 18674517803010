
import { defineComponent, ref, reactive, toRefs, Ref, provide, getCurrentInstance } from 'vue';
import { ElMessageBox } from 'element-plus';
import AddPerson from './components/AddPerson.vue';
import PackagedPagination from '@/components/pagination/Index.vue';
import { getShowBool } from '@/utils/permission-show-module';
import { getUserList, createUser, updateUser, delUser, updateUserStatus } from '@/api/company/users';
import ResetPassword from './ResetPassword.vue';
import { useSaList } from '@/shared/hooks/list';

// 用户状态
enum UserStatus {
  禁用 = -1,
  启用 = 0,
}

// 状态码
enum ResCode {
  Success,
}
interface TableState {
  multipleSelection: Array<any>;
  historyData: Array<object>;
}
interface RefAddDialog {
  openDialog: Function;
  [attr: string]: any;
}
// 密码重置
interface ResetFormState {
  newPassword: any[];
}
const passwdMsg = '长度在 8 到 16 个字符,只能输入大小写字母、数字、特殊字符（(!@#$%^&),至少1个大写字母，1个小写字母';
export default defineComponent({
  name: 'Person',
  components: { AddPerson, PackagedPagination, ResetPassword },
  setup() {
    // 表单相关状态
    const tableState: TableState = reactive({
      multipleSelection: [],
      historyData: [],
    });

    const refAddDialog: Ref<RefAddDialog | null> = ref(null);
    const resetPassword = ref<InstanceType<typeof ResetPassword>>();
    // 获取组件实例
    const instance = getCurrentInstance();

    // 提示信息
    function msgTips(type: string, content: string) {
      (instance as any).proxy.$message({
        type,
        message: content,
      });
    }

    // 打开对话框
    const openAddDialog = (): void => {
      (refAddDialog.value as RefAddDialog).openDialog();
    };

    const openEditDialog = (data: any): void => {
      (refAddDialog.value as RefAddDialog).openDialog('edit', { ...data, status: String(data.status) });
    };

    // 关闭对话框
    const closeDialog = () => {
      (refAddDialog.value as RefAddDialog).closeDialog();
    };

    // 初始化dialog
    const initAddDialog = () => {
      (refAddDialog.value as RefAddDialog).initDialog();
    };

    const { loading, list, total, query, fetchList, handleSearch, handlePageChange, handlePageSizeChange } = useSaList(
      async (query: any) => {
        loading.value = true;
        try {
          const {
            data: { rows, count },
          } = await getUserList({
            ...query,
          });
          total.value = count;
          list.value = rows;
        } catch (e) {
          console.log(e);
        }
        loading.value = false;
      },
    );
    fetchList();

    const selChange = (data: any): void => {
      tableState.multipleSelection = data;
    };

    // 查询
    // const filterAccount = debounce(getList, 1000);

    // 更新状态
    const handleUpdateStatus = async (status: number) => {
      const ids = tableState.multipleSelection.map((item) => item.id);
      const { code } = await updateUserStatus({ ids, status });
      if (code === ResCode.Success) {
        msgTips('success', '修改成功');
        fetchList();
      } else {
        msgTips('error', '修改失败');
      }
    };

    // 删除
    const handleDel = (): void => {
      ElMessageBox.confirm(`确定删除当前所选的人员?`, '提示', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        const ids = tableState.multipleSelection.map((item) => item.id);
        // 待传参
        const { code } = await delUser({ ids });
        if (code === ResCode.Success) {
          msgTips('success', '删除成功');
          fetchList();
        } else {
          msgTips('error', '删除失败');
        }
      });
    };

    // 新建
    const handleCreate = async (data: any) => {
      const { code } = await createUser({
        ...data,
        status: parseInt(data.status, 10),
      });
      if (code === ResCode.Success) {
        msgTips('success', '新建成功');
        initAddDialog();
        fetchList();
      } else {
        msgTips('error', '新建失败');
      }
    };

    // 编辑
    const handleEdit = async (data: any) => {
      const { code } = await updateUser({
        ...data,
        status: parseInt(data.status, 10),
      });
      if (code === ResCode.Success) {
        msgTips('success', '编辑成功');
        fetchList();
      } else {
        msgTips('error', '编辑失败');
      }
      closeDialog();
    };
    provide('handleCreate', handleCreate);
    provide('handleEdit', handleEdit);

    const handleResetPasswd = (data: any) => {
      const { id } = data;
      // eslint-disable-next-line no-unused-expressions
      resetPassword.value?.open(id);
    };
    return {
      ...toRefs(tableState),
      loading,
      list,
      total,
      query,
      fetchList,
      handleSearch,
      handlePageChange,
      handlePageSizeChange,
      handleUpdateStatus,
      openAddDialog,
      openEditDialog,
      handleDel,
      selChange,
      refAddDialog,
      UserStatus,
      passwdMsg,
      getShowBool,
      resetPassword,
      handleResetPasswd,
    };
  },
});
